import React, { useState, useEffect, useRef } from 'react';
import { DropdownIcon } from '../svgs/components';

const SelectDropDown = ({ options = [], placeholder = 'Select a country', selectedItem, setSelectedItem }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleSelect = option => {
    setSelectedItem(option);
    setIsOpen(false);
  };

  const filteredOptions = options?.filter(option =>
    option?.label?.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
  );

  const handleOutsideClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchTerm('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const displayedItem = selectedItem?.label || placeholder;

  return (
    <div className='select-box' ref={dropdownRef}>
      <div className='selected form-control type-filter position-relative' onClick={toggleDropdown}>
        <span className='selected-text'>{displayedItem}</span>
        <div className='position-absolute top-3 end-0'>
          <DropdownIcon />
        </div>
      </div>
      {isOpen && (
        <ul className='dropdown-menu options-container show'>
          {options.length > 0 && (
            <li className='search-box'>
              <input
                type='text'
                className='form-control py-1'
                placeholder='Search...'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </li>
          )}
          {filteredOptions?.length > 0 ? (
            filteredOptions?.map(option => (
              <li
                key={option.value}
                className={`dropdown-item option ${selectedItem?.value === option.value ? 'selected-option' : ''}`}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li className='dropdown-item text-muted'>No results</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectDropDown;
