import React from 'react';
import { Logout } from '../svgs/components';
import MaxabLogo from '../svgs/components/logo';
import SidebarMenuItem from './SidebarMenuItem';
import UserService from "../services/userService";
import {useGetRequest} from "../hooks/useRequests";
import {USER_ME_URL} from "../utils/urls";

const SideBarMenu = () => {
	const {data} = useGetRequest(
		'user-details',
		[],
		USER_ME_URL,
		null,
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: false
		}
	);

	return (
		<div className='sidebar'>
			<div>
				<div className='logo'>
					<MaxabLogo />
				</div>
				<div>
					<SidebarMenuItem permissions={data?.data?.permissions} />
				</div>
			</div>
			<div className='sidebar-footer'>
				<div className='profile-details'>
					<h3>{data?.data?.first_name} {data?.data?.last_name}</h3>
					<span>{data?.data?.email}</span>
				</div>
				<div className='logout-icon' onClick={() => {UserService.doLogout()}}><a><Logout /></a></div>
			</div>
		</div>
	);
};

export default SideBarMenu;
