import { ROUTES } from '../routes/Constants';
import UserService from '../services/userService';

export const CONFIGURATIONS = {
  OFFERS_BUDGET: 'Offers budget',
  TIMEZONE_AND_CURRENCY: 'Timezone & Currency',
};

export const SUPPORTED_FILE_TYPES = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const API_AUTH_CONFIG = () => {
  return {
    headers: {
      Authorization: `Bearer ${UserService.getToken()}`,
    },
  };
};

export const DATE_FORMAT = 'D/M/YYYY';

export const DATE_PICKER_FORMAT = 'dd/MM/yyyy';

export const BACKEND_DATE_FORMAT = 'YYYY-M-D';

export const PAGINATION_OFFSET = {
  PAGINATION_OFFSET_10: 10,
  PAGINATION_OFFSET_25: 25,
  PAGINATION_OFFSET_50: 50,
  PAGINATION_OFFSET_100: 100,
};

export const BASE_OPTION_ID = '-1';

export const OFFER_TYPES = [
  {
    id: -1,
    value: 'Offer type',
  },
  {
    id: 1,
    value: 'Weekly',
  },
  {
    id: 2,
    value: 'Monthly',
  },
  {
    id: 3,
    value: 'Flash Offer',
  },
  {
    id: 4,
    value: 'Generic Offer',
  },
];

export const OFFER_STATUS = [
  {
    id: -1,
    value: 'Offer status',
    validForAll: true,
    validForAwaiting: true,
  },
  {
    id: 'PENDING',
    value: 'Pending',
    validForAll: false,
    validForAwaiting: true,
  },
  {
    id: 'APPROVED',
    value: 'Approved',
    validForAll: true,
    validForAwaiting: true,
  },
  {
    id: 'DECLINED',
    value: 'Declined',
    validForAll: false,
    validForAwaiting: true,
  },
  {
    id: 'STARTED',
    value: 'Started',
    validForAll: true,
    validForAwaiting: true,
  },
  {
    id: 'REVOKED',
    value: 'Revoked',
    validForAll: false,
    validForAwaiting: true,
  },
  {
    id: 'EXPIRED',
    value: 'Expired',
    validForAll: true,
    validForAwaiting: true,
  },
  {
    id: 'ACHIEVED',
    value: 'Achieved',
    validForAll: true,
    validForAwaiting: true,
  },
  {
    id: 'REDEEMED',
    value: 'Redeemed',
    validForAll: true,
    validForAwaiting: true,
  },
  {
    id: 'REDEEMED_EXPIRED',
    value: 'Redeemed Expired',
    validForAll: true,
    validForAwaiting: true,
  },
];

export const DISABLED_STATUS_FOR_REVOKE_ACTION = ['EXPIRED', 'ACHIEVED', 'REDEEMED', 'REDEEMED_EXPIRED'];

export const CAN_CHANGE_BUDGET = 'change_budget';
export const CAN_APPROVE_OFFERS = 'approve_offers';
export const CAN_DECLINE_OFFERS = 'decline_offers';
export const CAN_REVOKE_OFFERS = 'revoke_offers';
export const CAN_VIEW_ALL_OFFERS = 'view_all_offers';
export const CAN_VIEW_WAITING_OFFERS = 'view_waiting_offers';
export const CAN_UPLOAD_OFFERS = 'upload_offers';
export const CAN_CHANGE_CONFIGURATION = 'change_configuration';

export const START_DATE_PARAM = 'start_date';
export const OFFER_STATUS_PARAM = 'status';
export const SYSTEM_ID_PARAM = 'main_system_ids';
export const USER_PARAM = 'submitted_by';
export const OFFER_TYPE_PARAM = 'offer_type';
export const SUBMISSION_DATE_PARAM = 'submission_date';
export const OFFER_ORDERING_PARAM = 'ordering';
export const COUNTRY_CODE_PARAM = 'country';

export const ALL_PERMISSIONS = [
  CAN_CHANGE_BUDGET,
  CAN_APPROVE_OFFERS,
  CAN_DECLINE_OFFERS,
  CAN_REVOKE_OFFERS,
  CAN_VIEW_ALL_OFFERS,
  CAN_VIEW_WAITING_OFFERS,
  CAN_UPLOAD_OFFERS,
  CAN_CHANGE_CONFIGURATION,
];

export const NON_AUTH = [ROUTES.LOGIN, ROUTES.ACCESS_DENIED];

export const DEFAULT_CONFIG_COUNTRY = 'EGP';
export const DEFAULT_CONFIG_CURRENCY = 'Egyptian Pound';

export const DESC_ORDER = '-id';
export const ASC_ORDER = 'id';

export const NETWORK_ERROR = 'Network Error';
export const ERROR_MESSAGE = 'Oops! Something went wrong.';

export const TOAST_SUCCESS_STATUS = 'Success';
export const TOAST_ERROR_STATUS = 'Error';

export const ENTER = 'Enter';

export const SHEET_VALIDATED_STATUS = 'VALIDATED';
export const SHEET_SAVED_STATUS = 'SAVED';
export const SHEET_ERROR_STATUS = 'FAILED';

export const ATTACHMENT_FILE_NAME_LENGHT = 50;

export const STAGE = 'stage';
export const PRODUCTION = 'prod';
