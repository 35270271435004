import React from "react";
import {QueryClientProvider, QueryClient} from "@tanstack/react-query";
import AuthenticatedWrapper from './common/AuthenticatedWrapper';
import {BrowserRouter} from "react-router-dom";
import packageInfo from "../package.json";
import CacheBuster from 'react-cache-buster';
import Loader from "./common/Loader";
import {PRODUCTION, STAGE} from "./utils/constants";
import './App.scss';

const queryClient = new QueryClient();

function App() {
  const isCacheBusterEnable = [STAGE, PRODUCTION].includes(
    process.env.REACT_APP_ENV
  );

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isCacheBusterEnable}
      isVerboseMode={false}
      loadingComponent={<Loader />}
    >
      <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="/app">
              <div className="App">
                  <AuthenticatedWrapper />
              </div>
          </BrowserRouter>
      </QueryClientProvider>
    </CacheBuster>
  );
}

export default App;
