import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import SpinLoader from '../common/SpinLoader';

const BaseModal = ({
    loading,
    showModal,
    modalIcon,
    heading,
    message,
    handleClose,
    handleSave,
}) => {

    return (
        <div>
            <Modal className='offers-modal' show={showModal}>
                <Modal.Body>
                    <div className='modalIcon'>{modalIcon}</div>
                    <h5>{ heading }</h5>
                    { message }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={loading}>
                        Download
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BaseModal;
