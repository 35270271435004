import React from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useAddMutation } from '../../hooks/useRequests';
import { CAN_CHANGE_BUDGET, TOAST_ERROR_STATUS, TOAST_SUCCESS_STATUS } from '../../utils/constants';
import { useFormik } from 'formik';
import { raiseToast, validatePermission } from '../../utils/utils';
import { OFFERS_BUDGET_URL } from '../../utils/urls';
import { CONFIG_BUDGET_ERROR_MESSAGE, CONFIG_BUDGET_SUCCESS_MESSAGE } from '../../utils/messages';
import SpinLoader from '../../common/SpinLoader';
import { isEmpty } from 'lodash';
import NoDataFound from '../../common/NoDataFound';

const OffersBudget = ({ permissions, data, refetch }) => {
  const offerData = data?.data || {};

  const canChangeBudget = validatePermission(CAN_CHANGE_BUDGET, permissions);

  const validationSchema = Yup.object().shape({
    offers_reward: Yup.number('Offers budget should be a number')
      .required('Offers budget is required')
      .positive('Offers budget should be a positive integer')
      .moreThan(0, 'Offers budget should be greater than 0')
      .integer('Offers budget should be an integer'),
    num_days: Yup.number('Budget period should be a number')
      .required('Budget period is required')
      .positive('Budget period should be a positive integer')
      .moreThan(0, 'Budget period should be greater than 0')
      .integer('Budget period should be an integer'),
    offer_reward: Yup.number('Max reward should be a number')
      .required('Max reward is required')
      .positive('Max reward should be a positive integer')
      .moreThan(0, 'Max reward should be greater than 0')
      .integer('Max reward should be an integer'),
  });

  const formik = useFormik({
    initialValues: {
      offers_reward: offerData?.offers_reward || '',
      num_days: offerData?.num_days || '',
      offer_reward: offerData?.offer_reward || '',
      country: data?.country || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => addBudget(),
    enableReinitialize: true,
  });

  const { mutate: addBudget, isLoading: loading } = useAddMutation(
    'offers-budget',
    [],
    OFFERS_BUDGET_URL,
    formik.values,
    null,
    () => {
      raiseToast(TOAST_SUCCESS_STATUS, CONFIG_BUDGET_SUCCESS_MESSAGE);
      refetch();
    },
    error => {
      raiseToast(TOAST_ERROR_STATUS, CONFIG_BUDGET_ERROR_MESSAGE);
    }
  );

  return (
    <>
      {isEmpty(offerData) ? (
        <NoDataFound />
      ) : (
        <div>
          <form className='form'>
            <div className='form-group'>
              <Form.Label>Offers budget</Form.Label>
              <Form.Control
                id='offers_reward'
                name='offers_reward'
                type='number'
                placeholder={`12,000`}
                onChange={formik.handleChange}
                value={formik.values.offers_reward}
                disabled={!canChangeBudget}
              />
              {formik.touched.offers_reward && formik.errors.offers_reward ? (
                <div className='errors'>{formik.errors.offers_reward}</div>
              ) : null}
            </div>
            <div className='line-break' />
            <div className='form-group'>
              <Form.Label>Budget period</Form.Label>
              <Form.Control
                id='num_days'
                name='num_days'
                type='number'
                placeholder='14 Days'
                onChange={formik.handleChange}
                value={formik.values.num_days}
                disabled={!canChangeBudget}
              />
              {formik.touched.num_days && formik.errors.num_days ? (
                <div className='errors'>{formik.errors.num_days}</div>
              ) : null}
            </div>
            <div className='line-break' />
            <div className='form-group'>
              <Form.Label>Max reward per offers</Form.Label>
              <Form.Control
                id='offer_reward'
                name='offer_reward'
                type='number'
                placeholder={`100`}
                onChange={formik.handleChange}
                value={formik.values.offer_reward}
                disabled={!canChangeBudget}
              />
              {formik.touched.offer_reward && formik.errors.offer_reward ? (
                <div className='errors'>{formik.errors.offer_reward}</div>
              ) : null}
            </div>
          </form>
          {canChangeBudget && (
            <div className='btn-wrapper'>
              <Button variant='light' type='reset' disabled={loading || !formik.dirty} onClick={formik.handleReset}>
                Cancel
              </Button>
              <Button
                variant='primary'
                type='submit'
                disabled={loading || !formik.dirty}
                onClick={formik.handleSubmit}
                className='save'
              >
                {loading ? (
                  <span>
                    Saving...
                    <SpinLoader variant='light' />
                  </span>
                ) : (
                  <span>Save</span>
                )}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OffersBudget;
