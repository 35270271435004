import React from 'react';
import { SyncLoader } from "react-spinners";

function Loader() {
  return (
    <div className="loader">
      <SyncLoader color="#195388" />
    </div>
  )
}

export default Loader;
