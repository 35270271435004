export const BUDGET_SUCCESS_MESSAGE = 'Budget updated successfully.';

export const CONFIG_UPDATE_SUCCESS_MESSAGE = 'Configurations updated successfully.';

export const OFFERS_REVOKE_SUCCESS_MESSAGE = 'Offers revoked.';
export const OFFERS_ACCEPTANCE_SUCCESS_MESSAGE = (count) =>  count ? `${count} offers accepted.` : 'Offer accepted.';
export const OFFERS_REJECTION_SUCCESS_MESSAGE = (count) => count ? `${count} offers rejected.` : 'Offer rejected.';

export const OFFERS_VALIDATION_SUCCESS_MESSAGE = `Offer(s) validated successfully.`;
export const OFFERS_VALIDATION_FAILURE_MESSAGE = (filename) => `Validations failed for ${filename}.`;
export const OFFERS_SAVE_SUCCESS_MESSAGE = (count) => `${count} saved successfully.`;
export const OFFERS_SAVE_FAILURE_MESSAGE = (filename) => `Failed to save ${filename}.`;

export const CONFIG_BUDGET_SUCCESS_MESSAGE = "Offers budget saved successfully.";
export const CONFIG_BUDGET_ERROR_MESSAGE = "Offers budget not saved.";
export const CONFIG_TIMEZONE_AND_CURRENCY_SUCCESS_MESSAGE = "Timezone & Currency saved successfully.";
export const CONFIG_TIMEZONE_AND_CURRENCY_ERROR_MESSAGE = "Timezone & Currency not saved.";

export const OFFERS_SUCCESS_REVOKED_MESSAGE = count => `${count} offer${count > 1 ? "s" : ""} revoked successfully.`;
export const OFFERS_SUCCESS_APPROVED_MESSAGE = count => `${count} offer${count > 1 ? "s" : ""} approved successfully.`;
export const OFFERS_SUCCESS_REJECTED_MESSAGE = count => `${count} offer${count > 1 ? "s" : ""} rejected successfully.`;
export const OFFERS_UNAFFECTED_MESSAGE = count => `${count} offer${count > 1 ? "s" : ""} unaffected.`;

export const INDIVIDUAL_OFFER_SUCCESS_APPROVED_MESSAGE = id => `Offer #${id} approved successfully.`;
export const INDIVIDUAL_OFFER_SUCCESS_REJECTED_MESSAGE = id => `Offer #${id} rejected successfully.`;
export const INDIVIDUAL_OFFER_FAILURE_REJECTED_MESSAGE = id => `Offer #${id} rejected.`;
export const AWAITING_APPROVAL_REJECTED_MESSAGE = id => `Offer #${id} can't be approved after the starting date.`;
export const OFFERS_REJECTED_MESSAGE = count => `${count} offer${count > 1 ? "s" : ""} can't be approved after the starting date.`;
export const INDIVIDUAL_OFFER_FAILURE_UNAFFECTED_MESSAGE = id => `Offer #${id} unaffected.`;

export const FILE_UPLOAD_SUCCESS_MESSAGE = "File uploaded successfully.";
export const FILE_UPLOAD_ERROR_MESSAGE = "Something went wrong with provided file.";
