import {
    ALL_PERMISSIONS,
    ATTACHMENT_FILE_NAME_LENGHT,
    TOAST_ERROR_STATUS,
    TOAST_SUCCESS_STATUS
} from "./constants";
import toast from "react-hot-toast";
import React from "react";

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const addOrUpdateURLParam = (url, param_key, param_value) => {
    let updatedURL = new URL(url);
    let params = updatedURL.searchParams;
    params.set(param_key, param_value);
    updatedURL.search = params.toString();
    return updatedURL.toString();
};

export const addOrUpdateURLParamForFilters = (url, param_key, param_value) => {
    let updatedURL = addOrUpdateURLParam(url, param_key, param_value)
    return addOrUpdateURLParam(updatedURL, 'page', 1)
}

export const deleteParamFromURL = (url, param) => {
    let updatedURL = new URL(url);
    updatedURL.searchParams.delete(param);
    return updatedURL.toString();
}

export const deleteURLParamForFilter = (url, param) => {
    let updatedUrl = deleteParamFromURL(url, param)
    return addOrUpdateURLParamForFilters(updatedUrl, 'page', 1)
}

export const validateAllPermissions = (permissions) => {
    const parsedPermissions = permissions?.map(item => {return item.codename});
    return ALL_PERMISSIONS.every(value => {
        return parsedPermissions?.includes(value)
    });
};

export const validatePermission = (permission, allowedPermissions) => {
    return allowedPermissions?.filter(obj => obj?.codename === permission)?.length > 0;
};

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

export const raiseToast = (toastStatus, toastMessage) => {
    toast((t) => (
            <ul className={`customToast ${toastStatus}`}>
                <li>{toastStatus}</li>
                <li>{toastMessage}</li>
            </ul>
        ),
        {
            duration: 3000,
        }
    );
};

export const setLocalStorageItem = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key, defaultValue = null) => {
    return JSON.parse(localStorage.getItem(key, defaultValue));
};

export const removeLocalStorageItem = key => {
    localStorage.removeItem(key);
};

export const handleSelectItem = (item, id) => {
    const idx = item.indexOf(id);
    if (idx === -1) return [...item, id];
    else return [...item.slice(0, idx), ...item.slice(idx + 1)];
};

export const raiseToastOnAction = (data, successMsg, failureMsg, offerID) => {
    const successCount = data?.data?.succeed;
    const failureCount = data?.data?.failed;

    if(successCount > 0) {
        raiseToast(TOAST_SUCCESS_STATUS, successMsg(offerID || successCount))
    }
    if (failureCount > 0) {
        raiseToast(TOAST_ERROR_STATUS, failureMsg(offerID || failureCount))
    }
};

export const turncateAttachmentFileName = attachmentFile => {
    attachmentFile = attachmentFile.split('.');
    let fileName = attachmentFile[0];
    let extension = attachmentFile[1]; 

    if (fileName.length > ATTACHMENT_FILE_NAME_LENGHT) {
        fileName = fileName.substring(0, ATTACHMENT_FILE_NAME_LENGHT);
    }

    return `${fileName}.${extension}`;
};
