import * as React from "react";
const SvgSortAscending = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 11"
    style={{
      enableBackground: "new 0 0 12 11",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M6 10.8c-.4 0-.7-.3-.7-.7V2.4L1.8 6c-.3.2-.7.2-.9 0-.3-.3-.3-.7 0-1L5.6.3c.1-.1.1-.1.2-.1h.4c.1 0 .1.1.2.1L11.1 5c.3.3.3.7 0 .9-.3.3-.7.3-.9 0L6.7 2.4v7.7c0 .4-.3.7-.7.7z"
      style={{
        fill: "#667085",
      }}
    />
  </svg>
);
export default SvgSortAscending;
