import React from 'react'
import {LogoLarge, Logout} from '../../svgs/components';
import UserService from '../../services/userService';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/Constants";

const Access = ({permissions}) => {
    const navigate = useNavigate();

    if (permissions && permissions.length > 0) {
      navigate(ROUTES.HOME);
    }

    return (
    <div className='access'>
      <div className='access-content'>
        <LogoLarge />
        <h1>You don’t have access</h1>
        <span>Please contact your admin to grant you access to this page</span>
        <a onClick={() => {UserService.doLogout()}} className='btn btn-light'>
            <Logout />Logout
        </a>
      </div>
    </div>
    )
};

export default Access;
