import * as React from "react";
const SvgUploadIcon = (props) => (
  <svg
    width={46}
    height={47}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={3} y={3.5} width={40} height={40} rx={20} fill="#F2F4F7" />
    <g clipPath="url(#uploadIcon_svg__a)">
      <path
        d="M26.333 26.833 23 23.5m0 0-3.333 3.333M23 23.5V31m6.992-2.175A4.167 4.167 0 0 0 28 21h-1.05a6.668 6.668 0 1 0-11.45 6.083"
        stroke="#475467"
        strokeWidth={1.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect
      x={3}
      y={3.5}
      width={40}
      height={40}
      rx={20}
      stroke="#F9FAFB"
      strokeWidth={6}
    />
    <defs>
      <clipPath id="uploadIcon_svg__a">
        <path fill="#fff" transform="translate(13 13.5)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUploadIcon;
