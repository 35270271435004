import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { validatePermission, setLocalStorageItem } from '../../utils/utils';
import { CAN_VIEW_ALL_OFFERS, CAN_VIEW_WAITING_OFFERS, CAN_UPLOAD_OFFERS } from '../../utils/constants';
import { ROUTES } from '../../routes/Constants';

const OffersTabs = ({ permissions }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const canViewAllOffers = validatePermission(CAN_VIEW_ALL_OFFERS, permissions);
  const canViewAwaitingOffers = validatePermission(CAN_VIEW_WAITING_OFFERS, permissions);
  const canUploadOffers = validatePermission(CAN_UPLOAD_OFFERS, permissions);

  useEffect(() => {
    if ([ROUTES.OFFERS, `${ROUTES.OFFERS}/`].includes(location.pathname)) {
      navigate(ROUTES.ALL_OFFERS);
    } else {
      setLocalStorageItem('offersPathname', location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (permissions && !(canViewAllOffers || canViewAwaitingOffers || canUploadOffers)) {
      navigate(ROUTES.CONFIGURATIONS);
    }
  }, [canViewAllOffers, canViewAwaitingOffers, canUploadOffers]);

  return (
    <div className='offers-tabs'>
      <div className='tabs'>
        {canViewAllOffers && <NavLink to={ROUTES.ALL_OFFERS}>All offers</NavLink>}
        {canViewAwaitingOffers && <NavLink to={ROUTES.AWAITING_APPROVAL}>Awaiting approval</NavLink>}
        {canUploadOffers && <NavLink to={ROUTES.UPLOAD_OFFERS}>Upload offers</NavLink>}
      </div>
      <Outlet />
    </div>
  );
};

export default OffersTabs;
