import React, { useEffect, useState } from 'react';
import { PaginationWrapper } from '../../common/PaginationWrapper';
import { RejectAll, SortAscending, SortDecending } from '../../svgs/components';
import { Table, Form } from 'react-bootstrap';
import { useGetRequest, useAddMutation, usePostRequest } from '../../hooks/useRequests';
import {
  DATE_FORMAT,
  PAGINATION_OFFSET,
  OFFER_TYPES,
  BACKEND_DATE_FORMAT,
  OFFER_STATUS,
  DISABLED_STATUS_FOR_REVOKE_ACTION,
  CAN_REVOKE_OFFERS,
  BASE_OPTION_ID,
  START_DATE_PARAM,
  OFFER_STATUS_PARAM,
  SYSTEM_ID_PARAM,
  OFFER_TYPE_PARAM,
  DESC_ORDER,
  ASC_ORDER,
  OFFER_ORDERING_PARAM,
  TOAST_ERROR_STATUS,
  ENTER,
  COUNTRY_CODE_PARAM,
} from '../../utils/constants';
import OffersModal from './OffersModal';
import moment from 'moment';
import Filters from './Filters';
import {
  deleteURLParamForFilter,
  addOrUpdateURLParam,
  addOrUpdateURLParamForFilters,
  validatePermission,
  raiseToast,
  handleSelectItem,
  raiseToastOnAction,
} from '../../utils/utils';
import { ALL_OFFERS_URL, OFFERS_COUNTRIES_URL, OFFERS_REWARD_URL, REVOKE_OFFERS_URL } from '../../utils/urls';
import { OFFERS_UNAFFECTED_MESSAGE, OFFERS_SUCCESS_REVOKED_MESSAGE } from '../../utils/messages';
import Loader from '../../common/Loader';

const AllOffersTable = ({ permissions }) => {
  const [selectedItem, setSelectItem] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [pageOffset, setPageOffset] = useState(PAGINATION_OFFSET.PAGINATION_OFFSET_10);
  const [pageNo, setPageNo] = useState(1);
  const [offersURL, setOffersURL] = useState(`${ALL_OFFERS_URL}?page=${pageNo}&page_size=${pageOffset}`);
  const [showOffersModal, setShowOffersModal] = useState(false);
  const [singleSelection, setSingleSelection] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');
  const [startDate, setStartDate] = useState();
  const [offerStatus, setOfferStatus] = useState();
  const [systemId, setSystemId] = useState();
  const [systemIdText, setSystemIdText] = useState('');
  const [offerType, setOfferType] = useState();
  const [order, setOrder] = useState(DESC_ORDER);
  const [showBulkRejectionModal, setShowBulkRejectionModal] = useState(false);
  const [rewardAmount, setRewardAmount] = useState('');

  const canRevokeOffer = validatePermission(CAN_REVOKE_OFFERS, permissions);

  const { data: offers, isFetching } = useGetRequest('all-offers', [], offersURL);
  const { data: countries, isFetching: countriesLoading } = useGetRequest(
    'configurations-supported-countries',
    [],
    OFFERS_COUNTRIES_URL
  );

  const { refetch: paginateAllOffers } = useGetRequest('all-offers', [], offersURL, null, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });

  const {
    data: reward,
    refetch: getRewardAmount,
    isFetching: isRewardLoading,
  } = usePostRequest(
    'all-offers-reward',
    [],
    OFFERS_REWARD_URL,
    {
      ids: selectedItem,
    },
    null,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );

  const handleCheckBox = (e, id) => setSelectItem(handleSelectItem(selectedItem, id));

  const handleSelectAll = () => {
    let ids = offers?.data?.ids;
    const allSelect = ids?.every(id => selectedItem.includes(id));

    if (allSelect) {
      setIsChecked(false);
      setSelectItem([]);
    } else {
      setIsChecked(true);
      setSelectItem(offers?.data?.ids);
    }
  };

  const onSuccess = data => {
    raiseToastOnAction(data, OFFERS_SUCCESS_REVOKED_MESSAGE, OFFERS_UNAFFECTED_MESSAGE);
    setShowOffersModal(false);
    setShowBulkRejectionModal(false);
    setSelectItem([]);
    setSingleSelection({});
  };

  const onFailure = error => {
    raiseToast(TOAST_ERROR_STATUS, OFFERS_UNAFFECTED_MESSAGE(error?.data?.failed));
    setShowOffersModal(false);
    setShowBulkRejectionModal(false);
  };

  const { mutate: handleSingleOfferRevoke, isLoading: loadingSingleRevokeOffer } = useAddMutation(
    'all-offers',
    [],
    REVOKE_OFFERS_URL,
    { ids: [singleSelection.id] },
    null,
    onSuccess,
    onFailure
  );

  const { mutate: handleBulkOffersRevoke, isLoading: loadingBulkRevokeOffer } = useAddMutation(
    'all-offers',
    [],
    REVOKE_OFFERS_URL,
    { ids: selectedItem },
    null,
    onSuccess,
    onFailure
  );

  const handleOfferRejection = () => handleSingleOfferRevoke();

  const handleOfferRejectionCancel = () => {
    setShowOffersModal(false);
    setSingleSelection({});
  };

  const handleBulkRejectionModalCancel = () => {
    setShowBulkRejectionModal(false);
    setSelectItem([]);
  };

  const handleBulkRejectionModal = () => handleBulkOffersRevoke();

  const handleCancelBtn = (e, item) => {
    e.preventDefault();
    setSingleSelection(item);
    setShowOffersModal(true);
  };

  const handleBulkRejection = e => {
    e.preventDefault();
    getRewardAmount();
    setShowBulkRejectionModal(true);
  };

  useEffect(() => {
    let ids = offers?.data?.ids;
    const allSelect = ids?.every(id => selectedItem.includes(id));
    if (allSelect && ids?.length > 0) setIsChecked(true);
    else setIsChecked(false);
  }, [selectedItem, offers]);

  useEffect(() => {
    setRewardAmount(reward?.data?.offers_reward);
  }, [reward]);

  useEffect(() => {
    let url = addOrUpdateURLParam(offersURL, 'page', pageNo);
    url = addOrUpdateURLParam(url, 'page_size', pageOffset);
    setOffersURL(url);
  }, [pageNo, pageOffset]);

  useEffect(() => {
    paginateAllOffers();
  }, [offersURL]);

  const handlePageOffset = offset => {
    setPageOffset(offset?.target?.value);
    setPageNo(1);
  };

  const handlePagination = ({ selected }) => setPageNo(selected + 1);

  const handleSearchFilterKeyPress = e => {
    if (e.key === ENTER) {
      if (e.target.value === '') {
        setOffersURL(deleteURLParamForFilter(offersURL, SYSTEM_ID_PARAM));
      }
      setSystemId(e.target.value);
    }
  };

  const handleStartDateChange = date => setStartDate(date);

  const handleOfferStatusChange = e => setOfferStatus(e.target.value);

  const handleOfferTypeChange = e => setOfferType(e.target.value);

  const handleSearchFilterChange = e => setSystemIdText(e.target.value);

  const searchFilter = {
    placeholder: 'Search by Retailer ID (comma separated)',
    handleKeyPress: handleSearchFilterKeyPress,
    handleChange: handleSearchFilterChange,
    value: systemIdText,
  };

  const startDateFilter = {
    placeholder: 'Start date',
    handleChange: handleStartDateChange,
    date: startDate,
  };

  const statusFilter = {
    placeholder: 'Status',
    handleChange: handleOfferStatusChange,
    offerTypes: OFFER_STATUS.filter(obj => obj.validForAll),
    offer: offerStatus,
  };

  const typeFilter = {
    placeholder: 'Offer type',
    handleChange: handleOfferTypeChange,
    offerTypes: OFFER_TYPES,
    offer: offerType,
  };

  const countryFilter = {
    placeholder: 'Country',
    selectedItem: selectedCountry,
    setSelectedItem: setSelectedCountry,
    countries: countries?.data?.map(country => ({ label: country?.code, value: country?.id })),
  };

  const handleOrderChange = () => setOrder(order === DESC_ORDER ? ASC_ORDER : DESC_ORDER);

  useEffect(() => {
    if (startDate || offerStatus || systemId || offerType || selectedCountry) setPageNo(1);

    let newUrl = offersURL;

    const updateUrlWithParam = (url, param, value) =>
      value === BASE_OPTION_ID ? deleteURLParamForFilter(url, param) : addOrUpdateURLParamForFilters(url, param, value);

    if (startDate)
      newUrl = addOrUpdateURLParamForFilters(newUrl, START_DATE_PARAM, moment(startDate).format(BACKEND_DATE_FORMAT));
    if (offerStatus) newUrl = updateUrlWithParam(newUrl, OFFER_STATUS_PARAM, offerStatus);
    if (systemId) newUrl = addOrUpdateURLParamForFilters(newUrl, SYSTEM_ID_PARAM, systemId);
    if (offerType) newUrl = updateUrlWithParam(newUrl, OFFER_TYPE_PARAM, offerType);
    if (order) newUrl = addOrUpdateURLParamForFilters(newUrl, OFFER_ORDERING_PARAM, order);
    if (selectedCountry) newUrl = addOrUpdateURLParamForFilters(newUrl, COUNTRY_CODE_PARAM, selectedCountry?.value);
    if (newUrl && newUrl !== offersURL) setOffersURL(newUrl);
  }, [startDate, offerStatus, offerType, systemId, order, selectedCountry]);

  const clearFilter = () => {
    let newUrl = offersURL;
    setOfferType(undefined);
    setSystemId(undefined);
    setSystemIdText('');
    setOfferStatus(undefined);
    setStartDate(undefined);
    newUrl = deleteURLParamForFilter(newUrl, OFFER_STATUS_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, OFFER_TYPE_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, SYSTEM_ID_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, START_DATE_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, COUNTRY_CODE_PARAM);
    setOffersURL(newUrl);
    selectedCountry && setSelectedCountry('');
  };

  useEffect(() => {
    if (systemIdText === '') {
      setOffersURL(deleteURLParamForFilter(offersURL, SYSTEM_ID_PARAM));
      setSystemId('');
    }
  }, [systemIdText]);

  return (
    <>
      {isFetching || countriesLoading ? (
        <Loader />
      ) : (
        <>
          <Filters
            searchFilter={searchFilter}
            startDateFilter={startDateFilter}
            statusFilter={statusFilter}
            typeFilter={typeFilter}
            countryFilter={countryFilter}
          />
          {(startDate ||
            systemIdText ||
            selectedCountry ||
            (offerStatus === BASE_OPTION_ID ? undefined : offerStatus) ||
            (offerType === BASE_OPTION_ID ? undefined : offerType)) && (
            <div className='d-flex justify-content-end mt-4'>
              <span className='clear-filter' onClick={clearFilter}>
                Clear Filter
              </span>
            </div>
          )}
          <div className='all-offers-table'>
            <Table>
              <thead className='tableHead'>
                <tr className='bulk-selected' key='all-offers-filter'>
                  {selectedItem && selectedItem.length > 0 && canRevokeOffer && (
                    <th colSpan={9}>
                      <div className='all-checked'>
                        {`${selectedItem?.length} ID${selectedItem?.length > 1 ? 's' : ''} selected`}
                        <a onClick={handleBulkRejection} href='#' className='revoked-all'>
                          <span>
                            <RejectAll />
                          </span>
                          {`Revoke ${selectedItem?.length} offer${selectedItem?.length > 1 ? 's' : ''}`}
                        </a>
                      </div>
                    </th>
                  )}
                </tr>
                <tr key='all-offers-table-header'>
                  <th className='w-12'>
                    <div className='sort-id'>
                      <Form.Check
                        className='checkbox'
                        disabled={!offers?.data?.ids?.length}
                        onChange={handleSelectAll}
                        checked={isChecked}
                      />
                      <p>ID</p>
                      <div onClick={handleOrderChange}>
                        {order === DESC_ORDER ? <SortDecending /> : <SortAscending />}
                      </div>
                    </div>
                  </th>
                  <th className='w-8'>Retailer ID</th>
                  <th className='w-9'>Start & End</th>
                  <th className='w-8'>Reward</th>
                  <th className='w-9'>Based on</th>
                  <th className='w-8-5'>Goal</th>
                  <th className='w-8'>Country</th>
                  <th className='w-9'>Offer Type</th>
                  <th className='w-6'>Status</th>
                  {canRevokeOffer && <th className='w-3'>Actions</th>}
                </tr>
              </thead>
              <tbody className='tableBody'>
                {offers?.data?.results?.map(item => (
                  <tr key={item.id}>
                    <td className='w-10'>
                      <div className='d-flex'>
                        <Form.Check
                          className='checkbox'
                          onChange={e => handleCheckBox(e, item.id)}
                          checked={selectedItem.includes(item.id)}
                        />
                        #{item.id}
                      </div>
                    </td>
                    <td className='grey w-8'>#{item.main_system_id}</td>
                    <td className='w-9'>
                      {moment(item.start_date).format(DATE_FORMAT)}
                      <span>{moment(item.end_date).format(DATE_FORMAT)}</span>
                    </td>
                    <td className='w-8'>
                      {item.reward}
                      <span>{item?.reward_type_display}</span>
                    </td>
                    <td className='grey w-9'>
                      {item?.goals.length > 1 ? (
                        <span className='grey'>Multiple</span>
                      ) : (
                        item?.goals?.map(goal => (
                          <div key={goal?.id}>
                            <span className='grey'>{goal?.based_on_display}</span>
                          </div>
                        ))
                      )}
                    </td>
                    <td className='w-9'>
                      {item?.goals.length > 1 ? (
                        <span>Multiple</span>
                      ) : (
                        item?.goals?.map(goal => <div key={goal?.id}>{goal?.target}</div>)
                      )}
                    </td>
                    <td className='w-8'>{item?.country_code || '-'}</td>
                    <td className='w-9'>
                      {item.offer_type_display}
                      <span>{item.arabic_name}</span>
                    </td>
                    <td className='w-6 p-0'>
                      <span className={`status-chip ${item.status_display.toLowerCase()}`}>
                        {item.status_display.toLowerCase()}
                      </span>
                    </td>
                    {canRevokeOffer && (
                      <td className='w-3'>
                        {DISABLED_STATUS_FOR_REVOKE_ACTION.includes(item.status) ? (
                          <a className='action cancel-btn disabled mx-auto' href='#'>
                            <RejectAll />
                          </a>
                        ) : (
                          <a className='action cancel-btn mx-auto' onClick={e => handleCancelBtn(e, item)} href='#'>
                            <RejectAll />
                          </a>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr key='all-offers-pagination'>
                  <td colSpan={9}>
                    <PaginationWrapper
                      currentPage={parseInt(pageNo)}
                      total={offers?.data?.count}
                      pageOffset={pageOffset}
                      handlePagination={handlePagination}
                      handlePageOffset={handlePageOffset}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </>
      )}
      {showOffersModal && (
        <OffersModal
          showOffersModal={showOffersModal}
          handleClose={handleOfferRejectionCancel}
          handleConfirmation={handleOfferRejection}
          isCancel={true}
          message={`You are about to revoke offer with total reward amount ${singleSelection.reward}`}
          heading={'Revoke Offer'}
          loading={loadingSingleRevokeOffer}
        />
      )}
      {showBulkRejectionModal && (
        <OffersModal
          showOffersModal={showBulkRejectionModal}
          handleClose={handleBulkRejectionModalCancel}
          handleConfirmation={handleBulkRejectionModal}
          isCancel={true}
          message={`You are about to revoke offer${
            selectedItem?.length > 1 ? 's' : ''
          } with a total reward amount of ${rewardAmount}.`}
          heading={`Revoke Offer${selectedItem?.length > 1 ? 's' : ''}`}
          loading={loadingBulkRevokeOffer}
          isRewardLoading={isRewardLoading}
        />
      )}
    </>
  );
};

export default AllOffersTable;
