import * as React from "react";
const SvgOffersIcon = (props) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.21 14.89A10 10 0 1 1 7 1.83M21 11A9.999 9.999 0 0 0 11 1v10h10Z"
      stroke="currentcolor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgOffersIcon;
