import React from 'react'
import AllOffersTable from './AllOffersTable'

const AllOffers = ({permissions}) => {
  return (
    <div>
      <AllOffersTable permissions={permissions} />
    </div>
  )
}

export default AllOffers;
