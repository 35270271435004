import * as React from "react";
const SvgAcceptAll = (props) => (
  <svg
    width={16}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.667 1 5.5 10.167 1.333 6"
      stroke="currentcolor"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAcceptAll;
