import * as React from "react";
const SvgErrorIcon = (props) => (
  <svg
    width={56}
    height={57}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={4} y={4.5} width={48} height={48} rx={24} fill="#FEF0C7" />
    <path
      d="M28 24.5v4m0 4h.01m-1.72-13.14L17.82 33.5a1.998 1.998 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-14.14a2.001 2.001 0 0 0-3.42 0Z"
      stroke="#DC6803"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={4}
      y={4.5}
      width={48}
      height={48}
      rx={24}
      stroke="#FFFAEB"
      strokeWidth={8}
    />
  </svg>
);
export default SvgErrorIcon;
