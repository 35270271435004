import React from 'react'
import { Google, LoginLogo } from '../../svgs/components'

const Login = () => {
  return (
    <div className='login'>
      <LoginLogo />
      <h1>Log in to your account</h1>
      <span>Welcome back! Please select your sign-in method</span>
      <a className='btn btn-light'><Google />Sign in with Google</a>
    </div>
  )
}

export default Login;
