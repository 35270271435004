import React from 'react'
import {useLocation} from 'react-router-dom';
import {Table} from "react-bootstrap";
import Header from "../../common/Header";

const ValidationErrors = () => {
    const location = useLocation()
    let validationHtml = '';

    Object.entries(location?.state?.data).forEach(([key, value]) => {
        value?.map(item => {
            validationHtml += `<tr><td>${key}</td><td>${item}</td></tr>`
        })
    })

    return (
        <>
            <Header title='Validation Report' subTitle='All errors in uploaded file of offers' />
            <div className='offers'>
                <div className='upload-file-validation-table'>
                    <Table>
                        <thead>
                        <tr>
                            <td>ROW NO</td>
                            <td>ERROR</td>
                        </tr>
                        </thead>
                        <tbody dangerouslySetInnerHTML={{__html: validationHtml}}>
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default ValidationErrors;
