import React from 'react'
import Header from '../../common/Header';
import OffersTabs from './OffersTabs'

const Offers = ({permissions}) => {
  return (
    <>
      <Header title='Offers' subTitle='Set offers and goals for retailers' />
      <div className='offers'>
        <OffersTabs permissions={permissions} />
      </div>
    </>
  )
}

export default Offers;
