import React from 'react';

const NoDataFound = () => {
  return (
    <div className='no-data-found'>
      <div className='no-data-icon'>📄</div>
      <h2>No Data Found</h2>
      <p>Sorry, we couldn't find any data to display.</p>
    </div>
  );
};

export default NoDataFound;
