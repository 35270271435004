import React from 'react'
import ReactPaginate from 'react-paginate';
import PageSizeField from './PageSizeField';
import _ from 'lodash';

export const PaginationWrapper = ({
    currentPage,
    total,
    pageOffset,
    handlePagination,
    handlePageOffset,
}) => {
    const totalPages = _.range(1, total/pageOffset + 1);

    return (
      <div className='pagination-wrapper'>
        <div className='items-number'>
          <span>No. of items</span>
          <PageSizeField handlePageOffset={handlePageOffset} pageOffset={pageOffset} />
        </div>
        <ReactPaginate
          pageCount={totalPages.length}
          onPageChange={page => handlePagination(page)}
          disabledClassName="disabled"
          initialPage={currentPage - 1}
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="page-item"
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          breakLinkClassName="page-link"
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    )
}
