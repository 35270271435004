import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../routes/Constants';
import { ConfigurationsIcon, OffersIcon } from '../svgs/components';
import { CAN_VIEW_ALL_OFFERS, CAN_VIEW_WAITING_OFFERS, CAN_UPLOAD_OFFERS } from '../utils/constants';
import { getLocalStorageItem, validatePermission } from '../utils/utils';

const SidebarMenuItem = ({ permissions }) => {
  const location = useLocation();
  const storedOffersPathname = getLocalStorageItem('offersPathname');
  // TODO:need to update this
  const storeConfigurationsPathname = getLocalStorageItem('configurationsPathname');

  return (
    <div className='sidebar-menu'>
      {(validatePermission(CAN_VIEW_ALL_OFFERS, permissions) ||
        validatePermission(CAN_VIEW_WAITING_OFFERS, permissions) ||
        validatePermission(CAN_UPLOAD_OFFERS, permissions)) && (
        <ul>
          <li key='1'>
            <Link
              className={`${location.pathname.includes(`${ROUTES.OFFERS}/`) && 'active'}`}
              to={storedOffersPathname || ROUTES.OFFERS}
            >
              {<OffersIcon />}
              {'Offers'}
            </Link>
          </li>
          <li key='2'>
            <Link
              className={`${location.pathname.includes(`${ROUTES.CONFIGURATIONS}`) && 'active'}`}
              to={ROUTES.CONFIGURATIONS}
            >
              {<ConfigurationsIcon />}
              {'Configurations'}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SidebarMenuItem;
