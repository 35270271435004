import React from 'react';
import {PAGINATION_OFFSET} from "../utils/constants";

const PageSizeField = ({handlePageOffset, pageOffset}) => {
    return (
        <select className='form-select' value={pageOffset} onChange={handlePageOffset}>
            <option defaultValue value={PAGINATION_OFFSET.PAGINATION_OFFSET_10}>
                {PAGINATION_OFFSET.PAGINATION_OFFSET_10}
            </option>
            <option value={PAGINATION_OFFSET.PAGINATION_OFFSET_25}>
                {PAGINATION_OFFSET.PAGINATION_OFFSET_25}
            </option>
            <option value={PAGINATION_OFFSET.PAGINATION_OFFSET_50}>
                {PAGINATION_OFFSET.PAGINATION_OFFSET_50}
            </option>
            <option value={PAGINATION_OFFSET.PAGINATION_OFFSET_100}>
                {PAGINATION_OFFSET.PAGINATION_OFFSET_100}
            </option>
        </select>
    );
};

export default PageSizeField;
