import React from 'react';
import DatePickerField from '../../common/DatePickerField';
import { BASE_OPTION_ID } from '../../utils/constants';
import SelectDropDown from '../../common/SelectDropDown';

const Filters = ({
  searchFilter,
  startDateFilter,
  endDateFilter,
  typeFilter,
  statusFilter,
  userFilter,
  countryFilter,
}) => {
  const renderDateFilter = filter => {
    const { placeholder, handleChange, date } = filter;
    return (
      <div className='form-group date-picker'>
        <DatePickerField date={date} placeholder={placeholder} handleChange={handleChange} />
      </div>
    );
  };

  const renderSearchField = filter => {
    const { placeholder, handleKeyPress, value, handleChange } = filter;
    return (
      <div className='form-group search'>
        <input
          type='text'
          className='form-control '
          placeholder={placeholder}
          onKeyDown={e => handleKeyPress(e)}
          onChange={e => handleChange(e)}
          value={value}
        />
      </div>
    );
  };

  const renderTypeFilter = filter => {
    const { placeholder, handleChange, offerTypes, offer } = filter;
    return (
      <div className='form-group type-filter'>
        <select name={placeholder} className='form-control type-filter' value={offer} onChange={e => handleChange(e)}>
          {offerTypes.map(item => {
            return item.id === offer ? (
              <option key={item.id} value={item.id} selected={true}>
                {item.value}
              </option>
            ) : (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const renderUserFilter = filter => {
    const { placeholder, handleChange, users, selectedUser } = filter;
    return (
      <div className='form-group type-filter'>
        <select
          name={placeholder}
          className='form-control type-filter'
          value={selectedUser}
          onChange={e => handleChange(e)}
        >
          <option value={BASE_OPTION_ID} selected={selectedUser === BASE_OPTION_ID}>
            Select User
          </option>
          {users?.map(item => {
            return item.id === selectedUser ? (
              <option key={item.id} value={item.id} selected={true}>
                {item.email}
              </option>
            ) : (
              <option key={item.id} value={item.id}>
                {item.email}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const renderCountryFilter = filter => {
    const { placeholder, countries, selectedItem, setSelectedItem } = filter;

    return (
      <div className='form-group type-filter'>
        <SelectDropDown
          options={countries}
          placeholder={placeholder}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
    );
  };
  return (
    <div className='filters'>
      {searchFilter && renderSearchField(searchFilter)}
      <div className='date-fields-wrapper'>
        {startDateFilter && renderDateFilter(startDateFilter)}
        {countryFilter && renderCountryFilter(countryFilter)}
        {statusFilter && renderTypeFilter(statusFilter)}
        {endDateFilter && renderDateFilter(endDateFilter)}
        {userFilter && renderUserFilter(userFilter)}
        {typeFilter && renderTypeFilter(typeFilter)}
      </div>
    </div>
  );
};

export default Filters;
