import React from 'react'
import { Spinner } from 'react-bootstrap'

const SpinLoader = ({ variant, isLargeLoader  }) => {
  return (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      variant={variant}
      className={isLargeLoader && "largeLoader"}
    />
  )
}

export default SpinLoader;
