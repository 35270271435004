import React from 'react';
import Dropzone from 'react-dropzone';
import { UploadIcon } from '../svgs/components';
import { SUPPORTED_FILE_TYPES } from '../utils/constants';

export const UploadFile = ({ handleChange, loadingFileValidation }) => {

  return (
    <div>
      <Dropzone onDrop={handleChange} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: `file-uploader ${loadingFileValidation ? "disabled" : ""}` })}>
            <div className='upload-icon'>
              <UploadIcon />
            </div>
            <div className='upload-text'>
              <h3>
                <span>Click to upload </span>or drag and drop{' '}
              </h3>
              <p>xlsx (max. 1000mb)</p>
            </div>
            <input 
              {...getInputProps()}
              disabled={loadingFileValidation}
              accept={SUPPORTED_FILE_TYPES}
            />
          </div>
        )}
      </Dropzone>
    </div>
  );
};
