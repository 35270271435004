import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ErrorIcon, SaveOfferModalIcon } from '../../svgs/components';
import { DEFAULT_CONFIG_COUNTRY } from '../../utils/constants';
import SpinLoader from '../../common/SpinLoader';
import Skeleton from 'react-loading-skeleton';

const OffersModal = ({
  showOffersModal,
  handleClose,
  handleConfirmation,
  isCancel,
  message,
  heading,
  loading,
  savingMessage = '',
  isRewardLoading,
}) => {
  const disclaimerText = message ? message : `You are about to save offers with total reward amount 500,000`;
  const disclaimerHeading = heading ? heading : 'Save';

  return (
    <div>
      <Modal className={`offers-modal ${isRewardLoading ? 'skeleton' : ''}`} show={showOffersModal}>
        <Modal.Body>
          <div className='modalIcon'>{isCancel ? <ErrorIcon /> : <SaveOfferModalIcon />}</div>
          <h5>{disclaimerHeading}</h5>
          {isRewardLoading ? <Skeleton width='100%' height='18px' /> : disclaimerText}
        </Modal.Body>
        {isRewardLoading ? (
          <div className='btn-wrapper'>
            <Skeleton height='44px' />
            <Skeleton height='44px' />
          </div>
        ) : (
          <Modal.Footer>
            <Button variant='light' onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirmation} disabled={loading}>
              {loading ? (
                <span>
                  {savingMessage}
                  <SpinLoader variant='light' />
                </span>
              ) : (
                <span>Confirm</span>
              )}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default OffersModal;
