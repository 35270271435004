import axios from "axios";
import { startsWith } from "lodash";
import UserService from "../services/userService";
import { ERROR_MESSAGE, NETWORK_ERROR, TOAST_ERROR_STATUS } from "./constants";
import { raiseToast } from "./utils";

axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
        const cb = () => {
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
    }
});

axios.interceptors.response.use(
    response => response,
    error => {
      const { config, response: { status } = {} } = error;
      const { showErrors } = config;
      if (error.message === NETWORK_ERROR) {
        raiseToast(TOAST_ERROR_STATUS, error.message)
      }

      if (startsWith(status, 5)) {
        raiseToast(TOAST_ERROR_STATUS, ERROR_MESSAGE)
      } 
      return Promise.reject(error);
    }
  )

export const createAuthenticatedPostRequest = (url, payload, config) => {
    return axios.post(
        url,
        payload,
        config
    )
};

export const createUnAuthenticatedPostRequest = (url, payload) => {
    return axios.post(
        url,
        payload,
    )
};

export const createAuthenticatedGetRequest = (url, config) => {
    return axios.get(url, config)
};

export const createUnAuthenticatedGetRequest = url => {
    return axios.get(url)
};

export const createAuthenticatedPatchRequest = (url, payload, config) => {
    return axios.patch(
        url,
        payload,
        config
    );
};

export const createUnauthenticatedPatchRequest = (url, payload) => {
    return axios.patch(url, payload,);
};

export const createAuthenticatedDeleteRequest = (url, config) => {
    return axios.delete(url, config)
};

export const createUnauthenticatedDeleteRequest = url => {
    return axios.delete(url);
};
