export const ROUTES = {
  HOME: '/',
  OFFERS: '/offers',
  ALL_OFFERS: 'all-offers',
  AWAITING_APPROVAL: 'awaiting-approval',
  UPLOAD_OFFERS: 'upload-offers',
  CONFIGURATIONS: '/configurations',
  ACCESS_DENIED: '/access',
  LOGIN: '/login',
  VALIDATION_ERRORS: '/validation-errors',
};
