import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import {
    createAuthenticatedGetRequest,
    createUnAuthenticatedGetRequest,
    createAuthenticatedPostRequest,
    createUnAuthenticatedPostRequest,
    createAuthenticatedPatchRequest,
    createUnauthenticatedPatchRequest,
    createAuthenticatedDeleteRequest,
    createUnauthenticatedDeleteRequest,
} from "../utils/apiHelper";

export const usePostRequest = (key, data, url, payload, token, options) => {
    const requestOptions = options ? options : { refetchOnWindowFocus: false, retry: false, }
    return useQuery(
        [key, data],
        () => {
            return token ? createAuthenticatedPostRequest(url, payload, token) : createUnAuthenticatedPostRequest(url, payload)
        },
        requestOptions
    );
};

export const useGetRequest = (key, data, url, token, options) => {
    const requestOptions = options ? options : { refetchOnWindowFocus: false, retry: false, }
    return useQuery(
        [key, data],
        () => {
            return token ? createAuthenticatedGetRequest(url, token) : createUnAuthenticatedGetRequest(url)
        },
        requestOptions
    )
}

export const useAddMutation = (key, data, url, payload, token, onSuccess, onError) => {
    const queryClient = useQueryClient();
    return useMutation(
        () => {return token ? createAuthenticatedPostRequest(url, payload, token) : createUnAuthenticatedPostRequest(url, payload)},
        {
            onSuccess: (data) => onSuccess(data),
            onError: (error) => onError(error),
            onSettled: () => {
                queryClient.invalidateQueries([key]);
            },
        })
};

export const useUpdateMutation = (key, data, url, payload, token, onSuccess, onError) => {
    const queryClient = useQueryClient();
    return useMutation(
        () => { return token ? createAuthenticatedPatchRequest(url, payload, token) : createUnauthenticatedPatchRequest(url, payload) },
        {
            onSuccess: () => onSuccess(),
            onError: () => onError(),
            onSettled: () => {
                queryClient.invalidateQueries([key])
            }
        }
    );
}

export const useDeleteMutation = (key, data, url, token, onSuccess, onError) => {
    const queryClient = useQueryClient();
    return useMutation(
        () => { return token ? createAuthenticatedDeleteRequest(url, token) : createUnauthenticatedDeleteRequest(url) },
        {
            onSuccess: () => onSuccess(),
            onError: () => onError(),
            onSettled: () => {
                queryClient.invalidateQueries([key])
            }
        }
    )
};
