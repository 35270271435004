import * as React from "react";
const SvgSaveOfferModalIcon = (props) => (
  <svg
    width={56}
    height={57}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={4} y={4.5} width={48} height={48} rx={24} fill="#D1FADF" />
    <path
      d="M38 27.58v.92a10 10 0 1 1-5.93-9.14M38 20.5 28 30.51l-3-3"
      stroke="#039855"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={4}
      y={4.5}
      width={48}
      height={48}
      rx={24}
      stroke="#ECFDF3"
      strokeWidth={8}
    />
  </svg>
);
export default SvgSaveOfferModalIcon;
