import React, { useEffect, useState } from 'react';
import { useGetRequest } from '../../hooks/useRequests';
import { CONFIGURATIONS_COUNTRIES_BUDGETS } from '../../utils/urls';
import Loader from '../../common/Loader';
import NoDataFound from '../../common/NoDataFound';
import OffersBudget from './OffersBudget';
import { isEmpty } from 'lodash';

const ConfigurationsTabs = ({ permissions }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabData, setTabData] = useState({ data: {}, country: {} });

  const {
    data: budgetsData,
    isFetching,
    refetch,
  } = useGetRequest('budgets-countries', [], CONFIGURATIONS_COUNTRIES_BUDGETS);

  useEffect(() => {
    setTabData({ data: budgetsData?.data?.[activeTab]?.budgets?.[0], country: budgetsData?.data?.[activeTab]?.id });
  }, [budgetsData?.data, activeTab]);

  return (
    <div>
      {isFetching ? (
        <Loader />
      ) : (
        <div className='configurations-tabs'>
          {budgetsData?.data?.length > 0 ? (
            <>
              <div className='tabs'>
                {budgetsData?.data?.map((tab, index) => (
                  <button
                    key={tab?.name}
                    className={`${activeTab === index ? 'active' : ''}`}
                    onClick={() => {
                      setActiveTab(index);
                      setTabData({
                        data: tab?.budgets?.[0],
                        country: tab?.id,
                      });
                    }}
                  >
                    {tab?.name}
                  </button>
                ))}
              </div>
              {!isEmpty(tabData?.data) ? (
                <OffersBudget key={`tab-${activeTab}`} permissions={permissions} data={tabData} refetch={refetch} />
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}
    </div>
  );
};

export default ConfigurationsTabs;
