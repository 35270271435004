import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_PICKER_FORMAT } from '../utils/constants';

const DatePickerField = ({ placeholder, date, handleChange }) => {
  return (
    <DatePicker
        dateFormat={DATE_PICKER_FORMAT}
        placeholderText={placeholder}
        selected={date}
        onChange={handleChange}
        onKeyDown={(e) => {e.preventDefault();}}
    />
  );
}

export default DatePickerField;
