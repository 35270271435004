import React from 'react'
import AwaitingApprovalTable from './AwaitingApprovalTable'

const AwaitingApproval = ({permissions}) => {
  return (
    <div className='awaiting-approval'>
      <AwaitingApprovalTable permissions={permissions} />
    </div>  
  )
}

export default AwaitingApproval;
