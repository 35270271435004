import * as React from "react";
const SvgDelete = (props) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 4.5h1.667m0 0H16.5m-13.333 0v11.667a1.667 1.667 0 0 0 1.666 1.666h8.334a1.667 1.667 0 0 0 1.666-1.666V4.5H3.167Zm2.5 0V2.833a1.667 1.667 0 0 1 1.666-1.666h3.334a1.667 1.667 0 0 1 1.666 1.666V4.5m-5 4.167v5m3.334-5v5"
      stroke="#667085"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDelete;
