import * as React from "react";
const SvgAttachedIcon = (props) => (
  <svg
    width={36}
    height={37}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2} y={2.5} width={32} height={32} rx={16} fill="#E3EFF9" />
    <path
      d="M24.667 17.887v.613a6.667 6.667 0 1 1-3.954-6.093m3.954.76L18 19.84l-2-2"
      stroke="#1C5E9B"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={2}
      y={2.5}
      width={32}
      height={32}
      rx={16}
      stroke="#ECF3FA"
      strokeWidth={4}
    />
  </svg>
);
export default SvgAttachedIcon;
