import * as React from "react";
const SvgInvalidFileError = (props) => (
  <svg
    width={36}
    height={37}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2} y={2.5} width={32} height={32} rx={16} fill="#FEE4E2" />
    <path
      d="M18 15.833V18.5m0 2.667h.007m6.66-2.667a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0Z"
      stroke="#D92D20"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={2}
      y={2.5}
      width={32}
      height={32}
      rx={16}
      stroke="#FEF3F2"
      strokeWidth={4}
    />
  </svg>
);
export default SvgInvalidFileError;
